import React from 'react'
import { PriceEstimatorContext } from '../../contexts/priceEstimatorContext'
export const ProgressBar = ({className}) => {
  const {step, productCategory} = React.useContext(PriceEstimatorContext)

  const percentage = productCategory === 'standard-models' ? step * 33.3 : step * 33.3
  return (
    <div className={className}>
      <Filler percentage={percentage} className={`${className}--filler`} />
    </div>
  )
}

const Filler = ({percentage, className}) => {
  return <div className={className} style={{width: `${percentage}%`, maxWidth: `100%`}} />
}
