import { parseLength, parseOnlyLetterAndSpace } from "../utils/inputParser"
import {
  checkAtLeastLength,
  checkEmailPattern,
  checkIsFilled
} from "../utils/inputValidator"

const contactModel = [
  {
    name: "name",
    label: "Name",
    type: "text",
    parseFun: parseOnlyLetterAndSpace,
    validators: [
      {
        id: "name-length",
        isValidFun: (expression) => checkAtLeastLength(expression, 3),
        alert:
          "We like to know who we're talking to, please enter your name :)",
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    validators: [
      {
        id: "email-pattern",
        isValidFun: checkEmailPattern,
        alert: "Email is not valid",
      },
      {
        id: "email-required",
        isValidFun: checkIsFilled,
        alert: "Email is empty",
      },
    ],
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
    validators: [
      {
        id: "phone-length",
        isValidFun: (expression) => checkAtLeastLength(expression, 9),
        alert: "Please enter a valid U.S phone number",
      },
    ],
  },
  {
    name: "deliveryAddress",
    label: "Delivery Address",
    type: "text",
    validators: [
      {
        id: "delivery-length",
        isValidFun: (expression) => checkAtLeastLength(expression, 6),
        alert: "Please enter a valid delivery address",
      },
      {
        id: "delivery-required",
        isValidFun: checkIsFilled,
        alert: "Delivery Address is empty",
      },
    ],
  },
  {
    name: "city",
    label: "City",
    type: "text",
    validators: [
      {
        id: "city-length",
        isValidFun: checkIsFilled,
        alert: "City is required",
      },
    ],
  },
  {
    name: "state",
    label: "State",
    type: "text",
    validators: [
      {
        id: "state-required",
        isValidFun: checkIsFilled,
        alert: "State is required",
      },
      {
        id: "state-length",
        isValidFun: (expression) => checkAtLeastLength(expression, 2),
        alert: "Please enter a valid U.S state",
      },
    ],
  },
  {
    name: "message",
    label: "Message",
    type: "textarea",
    parseFun: (expression) => parseLength(expression, 500),
    validators: [
      {
        id: "message-required",
        isValidFun: checkIsFilled,
        alert: "Message is required",
      },
      {
        id: "message-length",
        isValidFun: (expression) => checkAtLeastLength(expression, 20),
        alert: "Please leave a slightly more detailed message",
      },
    ],
  },
]

export default contactModel