import React from "react"
import useForm from "../../hooks/useForm"
import { TextareaInput, TextInput } from "./inputs"
export default function FormPanel({
  title,
  btnName,
  submitCallback,
  model,
  formClassName,
  contactPhone,
}) {
  const [inputs, handleBlur, setInputs, setSubmit] = useForm(
    model,
    submitCallback
  )

  const Components = { TextareaInput, TextInput }

  const capitalize = (exp) => exp.charAt(0).toUpperCase() + exp.slice(1)

  const renderInput = (input) => {
    const Component = Components[capitalize(input.type) + "Input"]
    return (
      <Component
        inputClassName={`${formClassName ? `${formClassName}__form-group` : "form__form-group"
          }--${input.type}`}
        key={input.name}
        handleBlur={handleBlur}
        setInputs={setInputs}
        {...input}
      />
    )
  }
  return (
    <form
      method="post"
      action="/thank-you/"
      data-netlify-honeypot="bot-field"
      data-netlify="true"
      name="Contact Form"
      className={formClassName ? formClassName : "form"}
    >
      <input type="hidden" name="form-name" value="Contact Form" />
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="referrer" />
      {typeof window !== 'undefined' &&
        <input type="hidden" name="referrer" value={window.location.href} />
      }
      {title && <h1>{title}</h1>}
      {inputs.map((input) => renderInput(input))}
      <div className={`${formClassName ? formClassName : "form"}__form-group`}>
        <input
          type="submit"
          value={btnName}
          className="btn btn--primary btn--small"
          disabled={!inputs.every((i) => i.alert === null)}
        />
      </div>
      <div className={`${formClassName ? formClassName : "form"}__form-group`}>
        <a href={`tel:${contactPhone}`} className="contact-form__phone">
          {contactPhone}
        </a>
      </div>
    </form>
  )
}
