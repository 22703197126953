import parse from 'html-react-parser'
import React from "react"
import { FiChevronDown } from "react-icons/fi"
import { normalizePath } from '../utils/normalizePath'
import UniversalLink from "./UniversalLink"

const DropdownMenu = ({
  id,
  nodes,
  isDropdownOpen,
  toggleDropdown,
  dropdownIndex,
  closeMenu,
}) => {
  return (
    <>
      <button
        aria-label="toggle dropdown"
        className={
          isDropdownOpen ** dropdownIndex === id
            ? "navigation__dropdown-toggle navigation__dropdown-toggle--rotate"
            : "navigation__dropdown-toggle"
        }
        onClick={() => {
          toggleDropdown(id)
        }}
      >
        <FiChevronDown />{" "}
      </button>
      {
        isDropdownOpen && dropdownIndex === id && (
          <ul className="navigation__dropdown-list">
            {nodes.map(item => {
              const {id, label, path, cssClasses, parentId, ...rest} = item
              const strippedUrl = path.replace('/product-categories', '')
              return (
                <li key={id} className="navigation__dropdown-list__item">
                  <UniversalLink
                    partiallyActive={true}
                    className="navigation__dropdown-list__link"
                    activeClassName="dropdown-item--active"
                    to={normalizePath(strippedUrl)}
                    {...rest}
                    onClick={() => {
                      toggleDropdown()
                      closeMenu()
                    }}
                  >
                    {parse(label)}
                  </UniversalLink>
                </li>
              )
            })}
          </ul>
        )
      }
    </>
  )
}

export default DropdownMenu
