export const normalizePath = (path) => {

  path?.replace('/product-categories', '')
  if (!path?.endsWith(`/`) && !path?.startsWith(`http`)) {
    path = `${path}/`
  }

  if (!path?.startsWith(`/`) && !path?.startsWith(`http`)) {
    path = `/${path}`
  }

  return path.replace('/product-categories', '')
}