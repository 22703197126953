import React from "react"
import { FiChevronDown } from "react-icons/fi"
export default CustomSelect
export  function CustomSelect({
  name,
  labelText,
  hintText,
  defaultValue,
  handleChange,
  children,
}) {
  const selectInput = React.useRef(null)

  React.useEffect(() => {
    selectInput.current.focus()
  }, [])
  return (
      <div className="form-group">
      <label htmlFor={name} className="form-group__label">
        {labelText}
      </label>
      <div className="form-group__select-wrapper">
        <select
          className="form-group__custom-select"
          ref={selectInput}
          onFocus={handleChange}
          defaultValue={defaultValue}
          onChange={handleChange}
          name={name}
          id={name}
        >
          <option value="" disabled>
            {hintText}
          </option>
          {children}
        </select>
        <FiChevronDown
          style={{
            position: `absolute`,
            right: `1rem`,
            top: `1rem`,
            pointerEvents: `none`,
          }}
        />
      </div>
    </div>
  )
}
