import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import useMenuState from "../hooks/useMenuState"
import { flatListToHierarchical } from "../utils/flatListToHierarchical"
import MenuItem from "./MenuItem"

export default function PrimaryMenu() {
  const { toggleMenu, isMenuOpen, browserWidth , closeMenu} = useMenuState()
  const { wpMenu } = useStaticQuery(graphql`
    query PrimaryMenuQuery {
      wpMenu(slug: { eq: "primary-menu" }) {
        name
        menuItems {
          nodes {
            id
            label
            path
            parentId
            cssClasses
          }
        }
      }
    }
  `)

  useEffect(() => {
    if(browserWidth >=996) {
      closeMenu()
    }

  }, [browserWidth])

  const hierarchicalMenu =  flatListToHierarchical(wpMenu.menuItems.nodes)
  return (
    <div
      className={
        isMenuOpen && browserWidth < 996
          ? `navigation navigation--primary navigation__toggle-visible`
          : "navigation navigation--primary"
      }
    >
      {browserWidth < 996 && (
        <button
          className="navigation__button"
          aria-label="Toggle Menu"
          onClick={() => toggleMenu()}
        >
          <span className="navigation__icon">&nbsp;</span>
        </button>
      )}
      <div className="navigation__background"></div>
      <nav className="navigation__nav">
        <ul className="navigation__list">
          {hierarchicalMenu.map((menuItem, index) => {
            return(
              <li className="navigation__item" key={menuItem.id}><MenuItem menuItem={menuItem} /></li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}
