import React from "react"
import contactFormModel from '../../models/contactFormModel'
import { formatPhone } from "../../utils/formatPhone"
import FormPanel from "./FormPanel"
export default function ContactForm({ phone }) {
  const submitCallback = (e) => {
    e.submit()
  }
  const formattedPhone = formatPhone(phone)
  return (
    <FormPanel
      footerPhone={phone}
      btnName="Send Message"
      submitCallback={submitCallback}
      model={contactFormModel}
      formClassName="contact-form"
      contactPhone={formattedPhone}
    />
  )
}
