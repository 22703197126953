import parse from "html-react-parser"
import React from "react"
import useMenuState from "../hooks/useMenuState"
import { availableIcons } from "../utils/availableIcons"
import { normalizePath } from "../utils/normalizePath"
import DropdownMenu from "./DropdownMenu"
import UniversalLink from "./UniversalLink"
const MenuItem = ({ menuItem }) => {
  const { id, label, children, cssClasses, path, ...rest } = menuItem
  const {
    isDropdownOpen,
    closeMenu,
    toggleDropdown,
    dropdownIndex,
  } = useMenuState()

  return (
    <>
      {availableIcons[cssClasses[0]]}
      <UniversalLink
        className="navigation__link"
        activeClassName="navigation__link--active"
        partiallyActive={path !== "/" ? true : false}
        to={normalizePath(path)}
        onClick={() => {
          closeMenu()
          toggleDropdown()
        }}
        target={rest.target}
      >
        {parse(label)}
      </UniversalLink>
      {children.length > 0 && (
        <DropdownMenu
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
          dropdownIndex={dropdownIndex}
          closeMenu={closeMenu}
          nodes={children}
          id={menuItem.id}
        />
      )}
    </>
  )
}

MenuItem.propTypes = {}

export default MenuItem
