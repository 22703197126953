import React, { useState } from "react"

const PriceEstimatorContext = React.createContext({})

const PriceEstimatorProvider = (props) => {
  const {
  productCategory,
  legHeight,
  productUri,
} = props
  const initialFormData = {
    size: {
      value: "",
      width: "",
      length: "",
      price: "",
    },
    legHeight: {
      value: "6' Tall",
      price: 0,
    },
    sidesClosed: {
      value: "0",
      price: 0,
    },
    endsClosed: {
      value: "0",
      price: 0,
    },
  }
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState(initialFormData)

  // proceed to next step
  const nextStep = (e, numberOfSteps = 5) => {
    e.preventDefault()
    if (step < numberOfSteps) {
      setStep((step) => step + 1)
    }
  }

  // go back to previous step
  const previousStep = (e) => {
    e.preventDefault()
    if (step >= 1) {
      setStep((step) => step - 1)
    }
  }

  // reset price estimator form
  const resetStep = (e) => {
    e.preventDefault()
    setStep(0)
    setFormData(initialFormData)
  }

  const calculateResults = (e, numberOfSteps = 5) => {
    e.preventDefault()
    setStep(numberOfSteps)
  }

  // get sides closed pricing for selected model
  const getSidesClosedPrice = (sidesClosed, length = 21, height = 6) => {
    if (productCategory !== "deluxe-garages") {
      return sidesClosed
        .filter((o) => parseInt(o.header) === parseInt(length))[0]
        .optionRowHeader.find(
          (o) => parseInt(o.optionRow) === (parseInt(height) || 6)
        ).optionPrice
    }
  }

  // handle fields change
    const handleChange = input => e => {
    const val = e.target.value
    const price = e.target.options[e.target.selectedIndex].dataset.price

    switch (e.target.name) {
      case "size":
        const splitSize = {
          width: val.split("x")[0],
          length: val.split("x")[1],
        }
        setFormData(formData => ({
          ...formData,
          [input]: {
            value: val,
            price: price || 0,
            width: splitSize.width,
            length: splitSize.length,
          },
        }))
        break

      // leg height
      case "legHeight":
        setFormData(formData => ({
          ...formData,
          [input]: {
            value: val || "6' Tall",
            price: price || 0,
          },
        }))
        break

      // everything else
      default:
        setFormData(formData => ({
          ...formData,
          [input]: {
            value: val,
            price: price || 0,
          },
        }))
        break
    }
  }

  return (
    <PriceEstimatorContext.Provider
      value={{
        ...props,
        formData,
        step,
        setStep,
        nextStep,
        previousStep,
        resetStep,
        calculateResults,
        getSidesClosedPrice,
        handleChange,
        legHeight,
        productCategory,
        productUri,
      }}
    >
      {props.children}
    </PriceEstimatorContext.Provider>
  )
}

export { PriceEstimatorContext, PriceEstimatorProvider }
