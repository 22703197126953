import React from "react"
import "typeface-merriweather"
import "typeface-poppins"
import "typeface-raleway"
import { Footer } from "./src/components/Footer"
import { Header } from "./src/components/Header"
import { MenuStateProvider } from "./src/contexts/menuStateContext"
import "./src/sass/main.scss"

const addScript = (d, s, id, source) => {
  let js
  if(d.getElementById(id)) {
    d.getElementById(id).remove()
  }
  js = d.createElement(s)
  js.id = id
  js.src = source
  d.getElementsByTagName("head")[0].appendChild(js)
}

export const onRouteUpdate = ({location}) => {
  const {pathname } = location
  if(pathname.includes('/who-we-are')) {
    addScript(
      document, "script", "EmbedSocialReviewsScript", "https://embedsocial.com/embedscript/ri.js"
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <MenuStateProvider>
      <Header />
      {element}
      <Footer />
    </MenuStateProvider>
  )
}
