import React from "react"
import {
  FaFileContract,
  FaMapMarkerAlt,
  FaMapSigns,
  FaPhone,
  FaRegImages,
  FaTags,
  FaWarehouse
} from "react-icons/fa"
import { FiSlash } from "react-icons/fi"
import { GiEnvelope } from "react-icons/gi"
import { GoCreditCard, GoInfo } from "react-icons/go"

export const availableIcons = {
  info: <GoInfo />,
  contract: <FaFileContract />,
  phone: <FaPhone />,
  tags: <FaTags />,
  warehouse: <FaWarehouse />,
  card: <GoCreditCard />,
  envelope: <GiEnvelope />,
  map: <FaMapMarkerAlt />,
  mapSign: <FaMapSigns />,
  images: <FaRegImages />,
  default: <FiSlash />,
}