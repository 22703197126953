import React from "react"
import { AiOutlineCopyright } from 'react-icons/ai'
import { FaFacebook } from "react-icons/fa"
import SVGFooterLogo from "../images/logo.png"
import ContactForm from "./forms/ContactForm"
import styled from "styled-components";

const MainHeaderText = styled.p`
  font-family: "Serif";
  font-weight: bold;
  font-size: 3rem;
  margin: 0;
  margin-top: 1rem;
`
const ValueHeader = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0;
  display: inline;
  font-family: "Serif";
`
const ValueText = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  margin: 0;
 display: inline;
`
const ValueContainer = styled.div`
  display: block;
`

export const Footer = () => {
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  let copyDate = new Date().getFullYear()
  return (
    <footer className="footer">
      <div className="row">
        <div className="col-1-of-2 u-center-text u-order-md-2">
          <h2 className="heading-secondary--footer u-margin-bottom-md">
            Get In Touch
          </h2>
          {url !== "/thank-you" && <ContactForm phone="+19316352244" />}
          <div className="footer__social-links u-margin-top-sm">
            <a
              className="frhzbus"
              target="_BLANK"
              href="https://www.bbb.org/nashville/business-reviews/tool-and-utility-sheds/watson-barn-rentals-in-morrison-tn-37062222#bbbseal"
              title="Watson Barn Rentals, LLC, Tool & Utility Sheds, Morrison, TN"
              rel="noopener noreferrer"
              style={{ height: `42px`, paddingLeft: `1rem` }}
            >
              <img
                src="https://seal-nashville.bbb.org/logo/frhzbus/watson-barn-rentals-37062222.png"
                alt="Watson Barn Rentals, LLC, Tool & Utility Sheds, Morrison, TN"
                style={{ marginBottom: 0 }}
              />
            </a>
            <a
              href="https://facebook.com/WatsonBarnRentals"
              target="_BLANK"
              rel="noopener noreferrer"
              aria-label="Link to our facebook page"
              className="footer__social-links--fb"
            >
              <FaFacebook />
            </a>
          </div>
        </div>
        <div className="col-1-of-2 u-order-md-1">
          <img src={SVGFooterLogo} alt="footer logo" className="footer__logo"/>
          <blockquote>
            <MainHeaderText>Watson Core Values</MainHeaderText>
            <ValueContainer>
              <ValueHeader>HONESTY: </ValueHeader>
              <ValueText>
                Our goal is to be honest in conversations with customers and team members alike. We will maintain an open, sincere environment, by giving each other the space to speak into our lives for areas of improvement.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>RESPECT: </ValueHeader>
              <ValueText>
                We commit to respecting each other in our unique skills and personalities; inspiring each other to become better individuals within our company and communities. We call everyone to a standard of unequivocal excellence while giving grace through mistakes that may be made.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>DILIGENCE: </ValueHeader>
              <ValueText>
                It is essential that we convey examples of taking responsibility, being reliable, and following through on projects to completion. We value self-motivated individuals that are constantly looking for ways to grow personally and professionally.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>CLARITY: </ValueHeader>
              <ValueText>
                We recognize the benefit and importance of clarity. We strive for clear simplicity in all of our interactions and communications.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>SERVICE: </ValueHeader>
              <ValueText>
                We commit to treating our employees exceptionally well to show that they matter. Investing in our employees not only improves them as a person, but also enables them to perform better for the company and the customers we serve.
              </ValueText>
            </ValueContainer>
          </blockquote>
           <small className="footer__copyright">
            Copyright <AiOutlineCopyright /> 2019 - {copyDate} Watson Barn
            Rentals, LLC. Use of this site constitutes acceptance of our{" "}
            <a
              href="https://www.watsonbarnrentals.com/terms-of-use"
              target="_BLANK"
              rel="noopener noreferrer"
              className="footer__copyright--link"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://www.watsonbarnrentals.com/privacy-policy"
              target="_BLANK"
              rel="noopener noreferrer"
              className="footer__copyright--link"
            >
              Privacy Policy
            </a>
          </small>
        </div>
      </div>
    </footer>
  )
}
