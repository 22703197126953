import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from 'react'
import { useScrollPosition } from '../hooks/useScrollPosition'
import PrimaryMenu from './PrimaryMenu'
export const Header =() =>{
  const [boxShadowClass, setBoxShadowClass] = useState("")
  useScrollPosition(
    ({currPos}) => {
      const isScrolled = currPos.y
      isScrolled < 0 ? setBoxShadowClass("header--add-shadow") : setBoxShadowClass("")
    }, [boxShadowClass]
  )

  const {file} = useStaticQuery(graphql`query HeaderLogoQuery {
  file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, layout: FIXED)
    }
  }
}
`)

  return (
    <header id="header" className={`header ${boxShadowClass}`}>
      <div className="row">
        <div className="header__logo-box">
          <Link to="/" className="header__logo">
            <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt="Logo" />
          </Link>
        </div>
        <div className="header__navigation">
          <PrimaryMenu />
        </div>
      </div>
    </header>
  );
}


