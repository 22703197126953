export const checkAtLeastLength = (expression, length) =>
  expression && expression.trim().length >= length

export const checkIsFilled = (exp) => exp && exp.length > 0

export const checkIsTrue = (exp) => exp

export const checkEmailPattern = (mail) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(mail)
}
