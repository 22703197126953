
import classNames from "classnames"
import React from "react"
import { ValidationAlert } from ".."
const TextInput = ({
  name,
  label,
  type,
  value,
  alert,
  setInputs,
  handleBlur,
  inputClassName,
  placeholder,
}) => {
  const formClassName = inputClassName.split("__")[0]
  const formGroupClasses = classNames(
    inputClassName,
    inputClassName.split("--")[0]
  )
  const inputWrapperClass = classNames({
    [`${formClassName}__input-wrapper`]: formClassName,
    [`form-group__input-wrapper`]: !formClassName,
  })
  const inputClass = classNames({
    [`${formClassName}__input`]: formClassName,
    "form-group__input": !formClassName,
    "has-error": alert,
  })

  const labelClass = classNames({
    [`${formClassName}__label`]: formClassName,
    "form-group__label": !formClassName,
    "has-error": alert,
  })
  const labelContentClass = classNames({
    [`${formClassName}__label-content`]: formClassName,
    "form-group__label-content": !formClassName,
    "has-error": alert,
  })
  return (
    <div className={formGroupClasses}>
      <div className={inputWrapperClass}>
        <input
          type={type}
          className={inputClass}
          id={name}
          name={name}
          value={value || ""}
          onChange={setInputs}
          onBlur={handleBlur}
          placeholder={placeholder || " "}
        />
        <label className={labelClass} htmlFor={name}>
          <span className={labelContentClass}>{label}</span>
        </label>
      </div>
      <ValidationAlert content={alert} />
    </div>
  )
}

TextInput.propTypes = {}

export default TextInput